.ml-2{
    margin-left: 2rem;
  }
  
  .ml-3{
    margin-left: 3rem;
  }
  
  .ml-4{
    margin-left: 4rem;
  }
  
  .float-right{
      float: right;
  }
  
  .float-left{
      float: left;
  }